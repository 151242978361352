
.task-content {
    height: 100%;
    min-height: calc(100vh - 172px);
    display: flex;
    flex-direction: column;
    .task-page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        height: 1%;
    }
    .sort-list {
        padding-left: 10px;
        .item {
            padding: 0 10px;
            margin: 0 0 15px 0;
            color: #666;
            cursor: pointer;
        }
        .sort-item {
            display: inline-block;
            padding: 2px 6px;
            margin: 0 5px;
        }
        .sort-item.current, .sort-item:hover {
            background: #BEC3F1;
            color: #2338E6;
            border-radius:4px;
        }
    }
    .task-list {
        /*margin-top: 20px;*/
        padding-left: 24px;
        margin-right: -20px;
        display: flex;
        flex-wrap: wrap;
        .list-item {
            width: calc(16% - 14px);
            margin: 20px 20px 0 0;
            border-radius: 8px;
            .container {
                width: 100%;
                border: 1px solid #E3E4EC;
                transition: all .25s ease-out;
                border-radius: 8px;
                cursor: pointer;
                .item-cover {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 138px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        /*border-radius:8px 8px 0 0;*/
                    }
                    img.sign {
                        position: absolute;
                        border-radius: 0;
                        top: 25px;
                        left: 0;
                        opacity: .8;
                        width: 80px;
                    }
                }
                .item-content {
                    padding: 10px;
                    .item-title {
                        display: block;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                    }
                    .item-info {
                        .item-author {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .item-avatar {
                                display: flex;
                                align-items: center;
                                margin: 10px 0;
                                img {
                                    width: 24px;
                                    height: 24px;
                                    margin-right: 10px;
                                    border-radius: 50%;
                                }
                                span {
                                    display: block;
                                    width: 50px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
                        .item-evaluate {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            font-size: 12px;
                            i {
                                color: #F19149;
                                margin-right: 2px;
                            }
                        }
                    }
                }
                .item-status {
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    margin: 0 10px;
                    border-top: 1px solid #E5E5E5;
                    .view-task {
                        color: #2338E6;
                    }
                    .finish-task {
                        color: #20C997;
                    }
                    .time-out-task {
                        color: #666666;
                    }
                }
            }
        }
    }
    .no-task-list {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pages-center {
        margin-bottom: 10px;
        margin-top: 0;
    }
    /*.task-page {*/
        /*height: calc(100% - 116px);*/
        /*::v-deep .el-scrollbar__wrap {*/
            /*overflow-x: hidden;*/
            /*padding: 0 40px;*/
        /*}*/
    /*}*/
}
